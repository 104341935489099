import { PagesPath } from "./utils/route";
import { Route, Routes } from "react-router-dom";
import MainPage from "./pages";
import GetInTouch from "./feature/get-in-touch";
import NotFoundPage from "./pages/404";
import PrivacyPoilicy from "./feature/privacy-poilicy";
import PositionPage from "./feature/position";
import CareersPage from "./pages/careers-page";
import TermsofService from "./feature/terms-of-service";
import { AboutUs } from "./feature/about-us";
import { Blog } from "./feature/blog";
import { Article } from "./feature/blog/article";

function App() {
  return (
    <Routes>
      <Route path={PagesPath.home} element={<MainPage />} />
      <Route path={PagesPath.careers} element={<CareersPage />} />
      <Route path={PagesPath.careers + "/:id"} element={<PositionPage />} />
      <Route path={PagesPath.privacyPoilicy} element={<PrivacyPoilicy />} />
      <Route path={PagesPath.getInTouch} element={<GetInTouch />} />
      <Route path={PagesPath.termsofService} element={<TermsofService />} />
      <Route path={PagesPath.aboutUs} element={<AboutUs />} />
      <Route path={PagesPath.blog} element={<Blog />} />
      <Route path={PagesPath.blogArticle} element={<Article />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/404" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
