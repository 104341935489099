
import { Link } from "react-router-dom";
import { flexCenteredBetween, flexHorizontally, forMobile } from "../../styles/adaptivity";
import { styled } from "@mui/material";

export const HeaderContent = styled('div')(() => ({
  position: 'relative',
    ...flexCenteredBetween,
    paddingTop: 31,
    maxWidth: 1310,
    margin: '0 auto',
    width: '100%',

    [forMobile]: {
      padding: '20px 16px'
    }
}));

export const HeaderLinks = styled('div')(() => ({
    ...flexHorizontally,
    gap: 50,
}));

export const HeaderLinksMobile = styled(HeaderLinks)(() => ({
  flexDirection: 'column',
  alignItems: 'start',
  marginTop: '100px',
  gap: 25,
}));

export const LinkStyled = styled(Link)(() => ({
  textDecoration: 'none',
  color: '#FFF',
}));

export const Light = styled('div')(() => ({
    left: '50%',
    position: 'absolute',
    width: 1218,
    height: 784,
    borderRadius: '1218px',
    opacity: 0.3,
    background: '#39CA88',
    filter: 'blur(317px)',
    transform: 'translate(-50%, -25%)',
    zIndex: -1,
    [forMobile]: {
      width: '100%',
    }
  }));


  export const MobileWrapper = styled('div')(() => ({
    background: 'linear-gradient(180deg, rgba(8, 9, 29, 0.80) 0%, rgba(36, 41, 131, 0.00) 100%)',
    backdropFilter: 'blur(24px)',
    position: 'fixed',
    width: '100%',
    height: '100vh',
    padding: '16px',
    top: 0,
    left: 0,
    zIndex: 1,
  }));


  export const Soon = styled('div')(() => ({
   padding: 4,
   color: '#FFF',
   fontSize: 12,
   letterSpacing: '-0.36px',
   borderRadius: 6,
  background: '#13152E',
  }));