import { Button, Typography, styled } from "@mui/material";
import { FlexCenteredVertically } from "../../styles/styled-component";
import { forMobile } from "../../styles/adaptivity";

 
export const TitleStyled = styled(Typography)(() => ({
    letterSpacing: '-6.58px',
    fontWeight: 500,
    maxWidth: 1129,
    marginTop: 132,
    textAlign: 'center',
    '& span': {
        color: '#43D090',
    },

    [forMobile]: {
        fontSize: 62,
        lineHeight: '62px',
        letterSpacing: '-4.34px',
        marginTop: 20,
        maxWidth: 343,
    }
}))

export const ButtonStyled = styled(Button)<{width?: number | string}>(({width}) => ({
    padding: 19,
    width: width,
    textTransform: 'initial',
    borderRadius: 12,
    color: '#FFF',
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: '-0.8px',
    lineHeight: 'normal',
   
   '&.MuiButton-outlined': {
    background: '#2B2D38',
    border: 'none',
   },

   '&.Mui-disabled': {
    color: '#FFF',
    cursor: 'not-allowed',
    pointerEvents: 'auto',
   },

   '&.MuiButton-contained': {
    background: '#39CA88',
    boxShadow: '0px 7px 32px 0px rgba(57, 202, 136, 0.30), 0px 4px 9.1px 1px #64F2B1 inset',
   
   }
}))

export const ContentWrap = styled(FlexCenteredVertically)(() => ({
    position: 'relative',
    maxWidth: 1129,
    flexDirection: 'column',
}))

export const LineWrap = styled('div')<{delay?: number}>(({delay = 0}) => ({
    position: 'absolute',

    '@keyframes line': {
        '0%': {
          transform: 'translateY(-70%)',
          opacity: 0,
        },
        '5%': {
            opacity: 0.5,
          },
          '20%': {
            opacity: 1,
          },

          '80%': {
            opacity: 0.5,
          },
        '100%': {
          transform: 'translateY(100%)',
          opacity: 0,
        }
      },

      animation: `line 5s linear ${delay}s infinite`,
}))

export const Figure = styled('div')(() => ({
    position: 'absolute',
    opacity: '0.05',
    background: '#39CA88',
}))

export const Circle = styled('div')(() => ({
    position: 'absolute',
    borderRadius: 662,
    opacity: 0.5,
    background: '#39CA88',
    filter: 'blur(241.39999389648438px)',
    width: 662,
    height: 598,
    right: '-25%',
    top: '-28%',
}))

export const DecorLine = styled('div')<{white?: boolean, delay?: number}>(({white, delay = 0}) => ({
    position: 'absolute',
   
    '&::after': {
        position: 'absolute',
        content: '" "',
        opacity: white ? 0.2 : 0.3,
        background: white ? 'linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)' : 'linear-gradient(90deg, #FFF 0%, #08091D 50%)',
        width: 1,
        height: '100%',
        top: 0,
        left: '51%',
        transform: 'translate(-50%, 0px)',
    },

    '@keyframes decorLine': {
        '0%': {
          transform: 'translateY(-150%)',
          opacity: 0,
        },
        '5%': {
            opacity: 0.5,
          },
          '20%': {
            opacity: 1,
          },

          '80%': {
            opacity: 0.5,
          },
        '100%': {
          transform: 'translateY(80%)',
          opacity: 0,
        }
      },

      '& svg': {
        animation: `decorLine 5s linear ${delay}s infinite`,
      }
}))

export const Wrapper = styled("div")(() => ({
  maxWidth: 1306,
  width: "100%",
  position: "relative",
  margin: "0 auto",

  [forMobile]: {
    padding: "0 16px",
  },
}));