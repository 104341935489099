import { FC, memo, useEffect, useState } from "react";
import {
  HeaderContent,
  HeaderLinks,
  HeaderLinksMobile,
  Light,
  LinkStyled,
  MobileWrapper,
  Soon,
} from "./styled";
import logo from "../../assets/svg/logo.svg";
import { PagesPath } from "../../utils/route";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useIsMobile } from "../../utils/use-is-mobile";
import { FlexCenteredVertically } from "../../styles/styled-component";

export const headerLinks = [
  { page: "Home", path: PagesPath.home },
  { page: "Careers", path: PagesPath.careers },
  { page: "About Us", path: PagesPath.aboutUs },
  { page: "Blog", path: PagesPath.blog },
];

export const Header: FC = memo(() => {
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "";
    }
    return () => {
      document.documentElement.style.overflow = "";
    };
  }, [isOpen]);

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <HeaderContent>
      <Light />
      <Link style={{ zIndex: 3 }} to={PagesPath.home}>
        <img src={logo} alt="logo" />
      </Link>

      {isMobile && (
        <div
          className={isOpen ? "burger burger-active" : "burger"}
          style={{ zIndex: 3 }}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="strip burger-strip-2">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}

      {!isMobile && (
        <HeaderLinks>
          {headerLinks.map((l) => (
            <LinkStyled key={l.page} to={l.path} onClick={closeMenu}>
              <Typography variant="body1" fontWeight={400}>
                {l.page}
              </Typography>
            </LinkStyled>
          ))}

          <FlexCenteredVertically gap="4px">
            <Typography variant="body1" fontWeight={400}>
              Products & Tools
            </Typography>
            <Soon>Soon</Soon>
          </FlexCenteredVertically>
        </HeaderLinks>
      )}

      {!isMobile && (
        <LinkStyled to={PagesPath.getInTouch}>
          <Typography variant="body1" fontWeight={700}>
            Get in Touch
          </Typography>
        </LinkStyled>
      )}

      {isMobile && isOpen && (
        <MobileWrapper>
          <HeaderLinksMobile>
            {headerLinks.map((l) => (
              <LinkStyled key={l.page} to={l.path}>
                <Typography variant="body1" fontWeight={400}>
                  {l.page}
                </Typography>
              </LinkStyled>
            ))}

            <FlexCenteredVertically gap="4px">
              <Typography variant="body1" fontWeight={400}>
                Products & Tools
              </Typography>
              <Soon>Soon</Soon>
            </FlexCenteredVertically>

            <LinkStyled to={PagesPath.getInTouch}>
              <Typography variant="body1" fontWeight={700}>
                Get in Touch
              </Typography>
            </LinkStyled>
          </HeaderLinksMobile>
        </MobileWrapper>
      )}
    </HeaderContent>
  );
});

Header.displayName = "Header";
