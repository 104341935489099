import React, { FC, memo, useEffect, useState } from "react";
import { DefaultWrapper } from "../../pages/default-wrapper";
import { Wrapper } from "../about-us";
import { FlexCenteredVertically } from "../../styles/styled-component";
import { useIsMobile } from "../../utils/use-is-mobile";
import { LineWrap } from "../home/styled";
import { Line } from "../../assets/tsx/line";
import { Badge } from "../../components/badge";
import { Typography } from "@mui/material";
import { Form } from "../../components/form";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { apiKey, apiUrl } from "../../utils/constants";
import moment from "moment";
import { ArticeContent, ArticeWrapper } from "./styled";
import { marked } from "marked";
import { ArticlePreview } from "./article-preview";

interface IProps {}

const getArticle = async (id: string) => {
  const res = await axios.get(
    `${apiUrl}/api/articles?filters[slug][$eq]=${id}&populate=*`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${apiKey}`,
      },
    }
  );
  return res.data;
};

const getArticlesByCategories = async (category: string) => {
  const res = await axios.get(
    `${apiUrl}/api/articles?filters[category][slug][$eq]=${category}&populate=*`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${apiKey}`,
      },
    }
  );
  return res.data;
};

export interface Blocks {
  body?: string;
  id: number;
  title?: string;
  __component: string;
}

export interface ArticleData {
  attributes: {
    createdAt: string;
    description: string;
    publishedAt: string;
    slug: string;
    title: string;
    updatedAt: string;
    blocks: Blocks[];
    category: {
      data: {
        id: number;
        attributes: {
          name: string;
          slug: string;
        };
      };
    };
    cover: {
      data: {
        attributes: {
          url: string;
        };
      };
    };
  };
  id: number;
}

export interface IArticle {
  data: ArticleData[];
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      total: number;
      pageCount: number;
    };
  };
}

export const Article: FC<IProps> = memo(() => {
  const isMobile = useIsMobile();
  const location = useLocation();
  const id = location.pathname.split("/").pop();

  const [articles, setArticles] = useState<IArticle>();
  const [articlesByCategory, setArticlesByCategory] = useState<IArticle>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!id) return;
    const fetchArticles = async () => {
      try {
        const data = await getArticle(id);
        setArticles(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchArticles();
  }, [id]);

  useEffect(() => {
    if (!articles?.data?.length) return;
    const fetchArticlesByCategory = async () => {
      try {
        const dataByCategory = await getArticlesByCategories(
          articles?.data[0].attributes.category.data.attributes.slug
        );

        setArticlesByCategory(dataByCategory);
      } catch (error) {}
    };
    fetchArticlesByCategory();
  }, [articles?.data]);

  if (!articles?.data?.length) {
    return null;
  }

  const article = articles?.data[0];

  return (
    <DefaultWrapper>
      <>
        <Wrapper>
          <FlexCenteredVertically
            flexDirection="column"
            marginTop={isMobile ? "20px" : "87px"}
            position="relative"
          >
            <LineWrap sx={{ left: "5%", top: "2%" }} delay={0.4}>
              <Line height={161} />
            </LineWrap>

            <LineWrap sx={{ right: "5%", top: "-2%" }} delay={0.9}>
              <Line height={161} />
            </LineWrap>
            <Badge
              text={moment(article.attributes.createdAt).format(
                "dddd, D MMM YYYY"
              )}
              type="secondary"
              size="medium"
            />

            <Typography
              fontSize={isMobile ? "42px" : "64px"}
              maxWidth={841}
              marginTop={isMobile ? "18px" : "20px"}
              fontWeight={500}
              lineHeight="93%"
              letterSpacing={isMobile ? "-2.94px" : "-4.48px"}
              textAlign="center"
            >
              {article.attributes.title}
            </Typography>
          </FlexCenteredVertically>
        </Wrapper>

        <ArticeWrapper>
          {article.attributes.blocks?.map((art) => (
            <div key={art.id}>
              {art?.title ? (
                <ArticeContent
                  dangerouslySetInnerHTML={{
                    __html: marked(art.title) as string,
                  }}
                />
              ) : null}
              {art?.body ? (
                <ArticeContent
                  dangerouslySetInnerHTML={{
                    __html: marked(art.body) as string,
                  }}
                />
              ) : null}
            </div>
          ))}
        </ArticeWrapper>

        {articlesByCategory && articlesByCategory?.data?.length > 0 ? (
          <Wrapper>
            <Typography
              fontSize={isMobile ? 40 : 48}
              fontWeight={600}
              marginTop={isMobile ? "100px" : "260px"}
              marginBottom={isMobile ? "30px" : "62px"}
              textAlign="center"
            >
              Similar Articles
            </Typography>
            <FlexCenteredVertically
              flexWrap="wrap"
              gap="32px"
              alignItems="start"
              sx={{
                paddingTop: isMobile ? "0px" : "30px",
                marginTop: isMobile ? "30px" : "62px",
              }}
            >
              {articlesByCategory?.data?.map((article) => (
                <ArticlePreview
                  key={article?.id}
                  date={article?.attributes?.createdAt}
                  image={article?.attributes?.cover?.data?.attributes?.url}
                  title={article?.attributes?.title}
                  desc={article?.attributes?.description}
                  link={article?.attributes?.slug}
                />
              ))}
            </FlexCenteredVertically>
          </Wrapper>
        ) : null}

        <Form
          title={"Join the team changing the future of web3"}
          badge="Join Beta"
          withMessage
        />
      </>
    </DefaultWrapper>
  );
});

Article.displayName = "Article";
