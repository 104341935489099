import { Typography } from "@mui/material";
import { FC, memo, useCallback, useState } from "react";
import { ButtonStyled, FormStyled, Wrapper } from "./styled";
import { DecorLine } from "../../feature/home/styled";
import { Line } from "../../assets/tsx/line";
import { InputStyled } from "../input";
import { Badge } from "../badge";
import { useIsMobile } from "../../utils/use-is-mobile";
import { useForm } from "../../hooks/use-form";
import done from "../../assets/svg/done.svg";
import { FlexCenteredVertically } from "../../styles/styled-component";
import LazyImage from "../lazy-image";

interface IProps {
  title: string;
  badge?: string;
  withMessage?: boolean;
}

export const EMAIL_REGEXP = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/i;

export const to = "info@gammaprime.fi";
export const from = "gammaprime@gmail.com";
export const subject = "Message from gammaprime.fi";

export const Form: FC<IProps> = memo(({ title, badge, withMessage }) => {
  const isMobile = useIsMobile();

  const [response, setResponse] = useState<{
    status?: string;
    message?: string;
  }>();

  const formInitValues: Record<string, string> = {
    email: "",
    name: "",
    message: "",
  };
  const errorsInitValues: Record<string, boolean> = {
    email: false,
    name: false,
    message: false,
  };

  const { onFieldChange, values, resetForm, errors, setErrors } = useForm({
    formInitValues,
    errorsInitValues,
  });

  const handleSubmit = useCallback(
    async (event: any) => {
      event.preventDefault();

      const validName = values.name.trim().length > 0;
      const validEmail = EMAIL_REGEXP.test(values.email);

      const emailData = {
        to,
        subject,
        from,
        ...values,
      };

      if (validName && validEmail) {
        try {
          const res = await fetch("https://dev.gammaprime.fi/sendEmail.php", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(emailData),
          });

          const result = await res.json();
          setResponse(result);
        } catch (error) {
          setResponse({ status: "error", message: "Failed to send email" });
        }

        resetForm();
      } else {
        if (!validName) {
          setErrors((prev) => ({ ...prev, name: true }));
        }
        if (!validEmail) {
          setErrors((prev) => ({ ...prev, email: true }));
        }
      }
    },
    [resetForm, setErrors, values]
  );

  return (
    <Wrapper className="wrapper" id="form">
      <DecorLine white sx={{ height: 765, left: "10%", top: "-3%" }}>
        <Line height={161} style={{ position: "relative", top: "60%" }} />
      </DecorLine>

      <DecorLine white sx={{ height: 684, right: "5%", top: "33%" }}>
        <Line height={161} style={{ position: "relative", top: "40%" }} />
      </DecorLine>
      {badge?.length ? (
        <Badge text={badge} type="secondary" size="medium" />
      ) : null}
      <Typography
        fontSize={isMobile ? "62px" : "93px"}
        fontWeight={500}
        maxWidth="1060px"
        lineHeight="93%"
        textAlign="center"
        marginTop="20px"
        dangerouslySetInnerHTML={{ __html: title }}
        className="title"
      />

      <FormStyled>
        {response?.status === "success" ? (
          <FlexCenteredVertically flexDirection="column">
            <LazyImage src={done} alt="done" />
            <Typography
              fontSize={36}
              fontWeight={700}
              letterSpacing={-2.16}
              lineHeight="86%"
              textAlign="center"
              marginTop="32px"
            >
              Done!
            </Typography>

            <Typography
              fontSize={16}
              fontWeight={400}
              letterSpacing={-0.48}
              lineHeight="86%"
              textAlign="center"
              marginTop="12px"
              maxWidth={182}
            >
              We will try to answer you as soon as possible!
            </Typography>

            <ButtonStyled
              onClick={() => setResponse({})}
              sx={{
                marginTop: "40px",
              }}
              fullWidth
            >
              Ок
            </ButtonStyled>
          </FlexCenteredVertically>
        ) : (
          <>
            <form onSubmit={handleSubmit}>
              <InputStyled
                type={"text"}
                lable={"Your Name"}
                onChangeValue={onFieldChange}
                value={values.name}
                name="name"
                error={errors.name}
              />

              <InputStyled
                type={"text"}
                lable={"Email"}
                sx={{ marginTop: "30px" }}
                onChangeValue={onFieldChange}
                value={values.email}
                name="email"
                error={errors.email}
              />

              {withMessage ? (
                <InputStyled
                  type={"text"}
                  lable={"Tell us about you"}
                  sx={{ marginTop: "30px" }}
                  onChangeValue={onFieldChange}
                  value={values.message}
                  name="message"
                  error={errors.message}
                />
              ) : null}

              <ButtonStyled
                type="submit"
                fullWidth
                disabled={
                  values.email.length < 1 ||
                  values.name.length < 1 ||
                  Object.values(errors).some((error) => error)
                }
              >
                Join Beta Now
              </ButtonStyled>
            </form>
          </>
        )}
      </FormStyled>
    </Wrapper>
  );
});

Form.displayName = "Form";
