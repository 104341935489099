//@ts-nocheck

import React, { FC, memo, useEffect, useState } from "react";
import { DefaultWrapper } from "../../pages/default-wrapper";
import { Wrapper } from "../about-us";
import { FlexCenteredVertically } from "../../styles/styled-component";
import { useIsMobile } from "../../utils/use-is-mobile";
import { LineWrap } from "../home/styled";
import { Line } from "../../assets/tsx/line";
import { Badge } from "../../components/badge";
import { Typography } from "@mui/material";
import { Form } from "../../components/form";
import { ArticlePreview } from "./article-preview";
import { apiUrl, apiKey } from "../../utils/constants";
import axios from "axios";

interface IProps {}

const getArticles = async () => {
  const res = await axios.get(`${apiUrl}/api/articles?populate=*`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${apiKey}`,
    },
  });

  return res.data;
};

export const Blog: FC<IProps> = memo(() => {
  const isMobile = useIsMobile();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const data = await getArticles();
        setArticles(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  if (!articles?.data?.length) {
    return null;
  }

  return (
    <DefaultWrapper>
      <>
        <Wrapper>
          <FlexCenteredVertically
            flexDirection="column"
            marginTop={isMobile ? "20px" : "87px"}
            position="relative"
          >
            <LineWrap sx={{ left: "5%", top: "2%" }} delay={0.4}>
              <Line height={161} />
            </LineWrap>

            <LineWrap sx={{ right: "5%", top: "-2%" }} delay={0.9}>
              <Line height={161} />
            </LineWrap>
            <Badge text="BLOG US" type="secondary" size="medium" />

            <Typography
              fontSize={isMobile ? "42px" : "64px"}
              maxWidth={841}
              marginTop={isMobile ? "18px" : "20px"}
              fontWeight={500}
              lineHeight="93%"
              letterSpacing={isMobile ? "-2.94px" : "-4.48px"}
              textAlign="center"
            >
              Stories and interviews of Gamma Prime
            </Typography>
          </FlexCenteredVertically>
        </Wrapper>

        <Wrapper>
          <FlexCenteredVertically
            flexWrap="wrap"
            gap="32px"
            alignItems="start"
            sx={{ paddingTop: "30px", marginTop: "62px" }}
          >
            {articles?.data?.map((article) => (
              <ArticlePreview
                key={article?.id}
                date={article?.attributes?.createdAt}
                image={article?.attributes?.cover?.data?.attributes?.url}
                title={article?.attributes?.title}
                desc={article?.attributes?.description}
                link={article?.attributes?.slug}
              />
            ))}
          </FlexCenteredVertically>
        </Wrapper>

        <Form title={"Join the team changing the future of web3"} />
      </>
    </DefaultWrapper>
  );
});

Blog.displayName = "Blog";
