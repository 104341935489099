import React, { FC, ReactElement, memo } from "react";
import { HelmetTags } from "../components/helmet";
import ScrollToTop from "../hooks/scrol-to-top";
import { Header } from "../components/header";
import { Footer } from "../components/footer";

interface IProps {
  children: ReactElement;
}

export const DefaultWrapper: React.FC<IProps> = memo(({ children }) => {
  return (
    <>
      <HelmetTags url={window.origin} />
      <ScrollToTop />
      <Header />
      {children}
      <Footer />
    </>
  );
});
