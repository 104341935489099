import { Typography } from "@mui/material";
import React, { FC, Suspense, memo, useCallback } from "react";
import {
  ButtonStyled,
  ContentWrap,
  Figure,
  LineWrap,
  TitleStyled,
  Wrapper,
} from "./styled";
import { FlexCenteredVertically } from "../../styles/styled-component";
import { Whitepapper } from "./components/whitepapper";
import { Form } from "../../components/form";
import { Line } from "../../assets/tsx/line";
import { useIsMobile } from "../../utils/use-is-mobile";
import { DecadesOfTradfi } from "./components/decades-of-tradfi";
import { Team } from "../../components/team";
const Join = React.lazy(() => import("./components/join"));
const RealYield = React.lazy(() => import("./components/real-yield"));
const WorldSection = React.lazy(() => import("./components/world-section"));

export const HomePage: FC = memo(() => {
  const isMobile = useIsMobile();

  const handleScroll = useCallback((event: any, id: string) => {
    event.preventDefault();
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <FlexCenteredVertically flexDirection="column" position="relative">
      <Figure
        sx={{
          width: 660,
          height: 996,
          transform: "rotate(160.84deg)",
          right: "-40%",
          top: "1%",
        }}
      />

      <Figure
        sx={{
          width: 660,
          height: 996,
          transform: "rotate(-160.84deg)",
          left: "-42%",
          top: "9%",
        }}
      />
      <ContentWrap>
        <LineWrap sx={{ left: "0%", top: "15%" }} delay={0.4}>
          <Line height={161} />
        </LineWrap>

        <LineWrap sx={{ left: "11%", top: "73%" }}>
          <Line height={161} />
        </LineWrap>

        <LineWrap sx={{ right: "0%", top: "25%" }} delay={0.2}>
          <Line height={100} />
        </LineWrap>

        <LineWrap sx={{ right: "11%", top: "65%" }} delay={0.6}>
          <Line height={161} />
        </LineWrap>
        <TitleStyled variant="h1">
          The next <span>generation</span> in crypto primitives
        </TitleStyled>

        <Typography
          variant={isMobile ? "body2" : "body1"}
          fontWeight={400}
          letterSpacing="-0.48px"
          sx={{ opacity: 0.6 }}
          mt={isMobile ? "16px" : "32px"}
          maxWidth="532px"
          textAlign="center"
          padding={isMobile ? "0 16px" : "0"}
        >
          Tokenizing Real Yield in Web3 through Crypto structured products and
          derivatives, anchored by institutional grade risk management.
        </Typography>

        <FlexCenteredVertically
          marginTop="24px"
          gap="16px"
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="center"
          width="100%"
          padding="0 16px"
        >
          <ButtonStyled
            variant="contained"
            width={isMobile ? "100%" : 210}
            onClick={(e) => handleScroll(e, "form")}
          >
            Be the first to join
          </ButtonStyled>

          <ButtonStyled
            variant="outlined"
            width={isMobile ? "100%" : 210}
            onClick={(e) => handleScroll(e, "whitepaper")}
          >
            Read Documentation
          </ButtonStyled>
        </FlexCenteredVertically>
      </ContentWrap>

      <Suspense fallback={<div />}>
        <WorldSection />
      </Suspense>

      <Suspense fallback={<div />}>
        <RealYield />
      </Suspense>

      <DecadesOfTradfi />

      <Suspense fallback={<div />}>
        <Join />
      </Suspense>

      <Whitepapper />

      <Wrapper>
        <Team />
      </Wrapper>

      <Form title={"Let’s  Revolutionize DeFi  Together"} badge="Join Beta" />
    </FlexCenteredVertically>
  );
});

HomePage.displayName = "HomePage";
